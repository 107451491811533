import { create } from 'zustand'
import { createTrackedSelector } from 'react-tracked'
import { getGameShowListCache, setGameShowListCache } from '@/helper/cache'
import produce from 'immer'
import { getMaintenanceStatus } from '@/apis/future/preferences'
import { MaintenanceEnum } from '@/constants/maintenance'
import { RequestCacheTimeEnum } from '@/constants/common'

type IStore = ReturnType<typeof getStore>

// const MaintenancePreferences = {
//   maintenanceStatus: MaintenanceEnum.normal, // 是否维护，no_in 不可进入 no_show 不显示入口、normal 取消维护
//   defaultWalletModeCd: '', // 资金划转 fund_transfer/单一钱包 single_wallet
//   defaultSymbol: '', // 默认币种
//   maintenanceInfo: '', // 维护信息
// }

function getStore(set, get) {
  return {
    maintenance: MaintenanceEnum.normal,
    // 获取维护配置
    setMaintenanceStatus: async () => {
      const { isOk, data } = await getMaintenanceStatus({})
      if (isOk && data) {
        set(
          produce((draft: IStore) => {
            draft.maintenance = data?.maintenanceStatusCd ?? MaintenanceEnum.normal
          })
        )
      }
    },
    gameList: getGameShowListCache() || {
      code: '',
      title: '',
      type: '',
      id: '',
      pageNum: 0,
      total: 0,
      supplierInfo: '',
      sortId: '',
      data: [],
    },
    setGameList: data =>
      set(
        produce((draft: IStore) => {
          setGameShowListCache(data)
          draft.gameList = data
        })
      ),
    clearGameList: () =>
      set(
        produce((draft: IStore) => {
          const params = {
            code: '',
            title: '',
            type: '',
            id: '',
            pageNum: 0,
            total: 0,
            supplierInfo: '',
            sortId: '',
            data: [],
          }
          setGameShowListCache(params)
          draft.gameList = params
        })
      ),
    pageCache: {},
    setPageCache: (key, value) =>
      set(
        produce((draft: IStore) => {
          draft.pageCache[key] = {
            data: value,
            lastTime: performance.now(),
          }
        })
      ),
    getPageCache: key => {
      const cache = get().pageCache[key]
      return cache?.data ?? null
      // if (!cache) return null
      // if (performance.now() - cache.lastTime >= RequestCacheTimeEnum.homeGameList) {
      //   set(
      //     produce((draft: IStore) => {
      //       draft.pageCache[key] = null
      //     })
      //   )
      //   return null
      // }
      // set(
      //   produce((draft: IStore) => {
      //     draft.pageCache[key] = {
      //       data: cache.data,
      //       lastTime: performance.now(),
      //     }
      //   })
      // )
      // return cache.data
    },
  }
}
const baseGameStore = create(getStore)

const useGameStore = createTrackedSelector(baseGameStore)

export { useGameStore, baseGameStore }
